<template>
  <register-form :initial-pasien="pasien" :editing="true" />
</template>

<script>
import RegisterForm from "../../components/RegisterForm.vue";

export default {
  name: "Edit",

  components: {
    RegisterForm,
  },
  data() {
    return {
      pasien: this.$store.getters.pasienById(this.$route.params.id),
    };
  },
};
</script>
